import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faComment,
  faTag,
  faXmark,
  faPlus,
  faCirclePlus,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faTrash,
  faRepeat,
  faEllipsis,
  faEllipsisVertical,
  faChevronCircleRight,
  faChevronCircleLeft,
  faQuestionCircle,
  faTable,
  faCopy,
  faScissors,
  faPaste,
  faTrashCan,
  faBolt,
  faCheck,
  faMessage,
  faSquare,
  faSquarePlus,
  faTriangleExclamation,
  faArrowRotateRight,
  faCodeCommit,
  faUnlink,
  faCalculator,
  faGamepad,
  faNoteSticky,
  faImage,
  faArrowRightFromBracket,
  faFile,
  faFileArrowUp,
  faFileCode,
  faBars,
  faDoorOpen,
  faHourglassHalf,
  faLock,
  faEarthOceania,
  faCircleDot,
  faFileLines,
  faMugHot,
  faCircleDown,
  faArrowUpFromBracket,
  faPen,
  faSatellite,
  faUserAstronaut,
  faQuestion,
  faFilePdf,
  faVial,
  faVials,
  faBorderAll,
  faDiceFour,
  faEye,
  faRoute,
  faStar,
  faGear,
  faFlask,
  faUsers,
  faHouse,
  faDownLeftAndUpRightToCenter,
  faClone,
  faGripLines,
  faSpinner,
  faImages,
  faFolderBlank,
  faFolder,
  faChevronRight,
  faBriefcase,
  faRightToBracket,
  faClockRotateLeft,
  faBookmark,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  faCertificate,
  faRectangleList,
  faAlignLeft,
  faAlignJustify,
  faHeading,
  faListUl,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { app } from "./main";

// TODO(anton): I don't like this setup. This is ridiculous amount of boilerplate for icons.

export const initIcons = () => {
  /* add icons to the library */
  library.add(
    fa1,
    fa2,
    fa3,
    fa4,
    fa5,
    faImages,
    faComment,
    faGamepad,
    faTag,
    faXmark,
    faCheck,
    faPlus,
    faCirclePlus,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faTrash,
    faTrashCan,
    faRepeat,
    faEllipsis,
    faEllipsisVertical,
    faChevronCircleRight,
    faChevronCircleLeft,
    faQuestionCircle,
    faTable,
    faCopy,
    faScissors,
    faPaste,
    faBriefcase,
    faBolt,
    faMessage,
    faSquare,
    faSquarePlus,
    faTriangleExclamation,
    faArrowRotateRight,
    faCodeCommit,
    faUnlink,
    faCalculator,
    faNoteSticky,
    faImage,
    faArrowRightFromBracket,
    faFile,
    faFileArrowUp,
    faFileCode,
    faBars,
    faDoorOpen,
    faHourglassHalf,
    faLock,
    faEarthOceania,
    faCircleDot,
    faFileLines,
    faMugHot,
    faCircleDown,
    faArrowUpFromBracket,
    faPen,
    faSatellite,
    faUserAstronaut,
    faQuestion,
    faFilePdf,
    faVial,
    faVials,
    faBorderAll,
    faEye,
    faDiceFour,
    faRoute,
    faStar,
    faGear,
    faFlask,
    faUsers,
    faHouse,
    faDownLeftAndUpRightToCenter,
    faClone,
    faGripLines,
    faSpinner,
    faFolderBlank,
    faRightToBracket,
    faClockRotateLeft,
    faBookmark,
    faCertificate,
    faRectangleList,
    faAlignLeft,
    faAlignJustify,
    faHeading,
    faListUl
  );

  app.component("font-awesome-icon", FontAwesomeIcon);
};
