import { db } from "@/firebase";
import { app } from "@/main";
import { doc, getDoc } from "firebase/firestore";
import posthog from "posthog-js";
import { createRouter, createWebHistory } from "vue-router";
import { getCurrentUser } from "vuefire";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/welcome",
      component: () => import("../pages/WelcomePage.vue"),
      meta: {
        title: "Briefly, Welcome",
      },
      beforeEnter: async (to, from, next) => {
        const currentUser = await getCurrentUser();
        if (currentUser) {
          const userProfile = await getDoc(doc(db, `users/${currentUser.uid}`));
          const workspace = userProfile.data()?.homeWorkspace;
          next({
            path: workspace ? `workspaces/${workspace}` : "/experiments/new",
          });
        } else {
          next();
        }
      },
      children: [
        {
          path: "signup",
          name: "Sign up",
          component: () => import("../pages/SignUp.vue"),
          alias: "/signup",
          meta: {
            title: "Sign up",
          },
        },
        {
          path: "/signup/industry",
          name: "Sign up – Industry",
          component: () => import("../pages/SignUpIndustry.vue"),
          meta: {
            title: "Sign up",
          },
        },
        {
          path: "login",
          name: "Log in",
          component: () => import("../pages/LogIn.vue"),
          alias: "/login",
          meta: {
            title: "Log in",
          },
        },
      ],
    },
    {
      path: "/",
      name: "Home",
      component: () => null,
      beforeEnter: async (to, from, next) => {
        const currentUser = await getCurrentUser();
        if (!currentUser) {
          next({ path: "/signup" });
        } else {
          const userProfile = await getDoc(doc(db, `users/${currentUser.uid}`));
          const workspace = userProfile.data()?.homeWorkspace;
          next({ path: workspace ? `workspaces/${workspace}` : "/new" });
        }
      },
    },
    {
      path: "/error",
      name: "Error",
      component: () => import("../pages/ErrorPage.vue"),
      meta: {
        title: "Error",
      },
    },
    {
      path: "/",
      component: () => import("../WrapperComponent.vue"),
      children: [
        {
          path: "users/:workspaceId/methods/:methodId",
          name: "nosheet",
          components: {
            default: () => import("../MethodPage.vue"),
            topBarMiddle: () => import("../components/TopBar/MethodTopBar.vue"),
          },
          beforeEnter: async (to, from, next) => {
            const { workspaceId, methodId } = to.params;
            const methodDoc = await getDoc(
              doc(db, `workspaces/${workspaceId}/methods/${methodId}`)
            );
            if (methodDoc.exists()) {
              const methodData = methodDoc.data();
              if (methodData.title.length > 0) {
                to.meta.title = methodData.title;
              } else {
                to.meta.title = "Untitled Protocol";
              }
            }
            next();
          },
          children: [
            {
              path: "sheet",
              name: "sheet",
              components: {
                spreadsheet: async () => {
                  const sf = await import("@syncfusion/ej2-vue-spreadsheet");
                  app.use(sf.SpreadsheetPlugin);
                  return import(
                    "../components/Spreadsheet/MethodSpreadsheet.vue"
                  );
                },
              },
            },
          ],
        },
        // NOTE: This is a copy of the method routing adjusted for experiments
        // In reality experiments should have different integration with a
        // spreadsheet and a different top bar.
        {
          path: "users/:workspaceId/experiments/:experimentId",
          name: "nosheet-exp",
          components: {
            default: () => import("../ExperimentPage.vue"),
            topBarMiddle: () =>
              import("../components/experiments/ExperimentTopBar.vue"),
          },
          beforeEnter: async (to, from, next) => {
            const { workspaceId, experimentId } = to.params;
            const experimentDoc = await getDoc(
              doc(db, `workspaces/${workspaceId}/experiments/${experimentId}`)
            );
            if (experimentDoc.exists()) {
              const experimentData = experimentDoc.data();
              if (experimentData.title.length > 0) {
                to.meta.title = experimentData.title;
              } else {
                to.meta.title = "Untitled Labnote";
              }
            }
            next();
          },
          children: [
            {
              path: "sheet/:guid?",
              name: "sheet-exp",
              components: {
                spreadsheet: async () => {
                  const sf = await import("@syncfusion/ej2-vue-spreadsheet");
                  app.use(sf.SpreadsheetPlugin);
                  return import(
                    "../components/Spreadsheet/ExperimentSpreadsheet.vue"
                  );
                },
              },
            },
          ],
        },

        {
          path: "test",
          name: "Test editor",
          components: {
            default: () => import("../pages/MethodPageV2.vue"),
            topBarMiddle: () => import("../components/TopBar/MethodTopBar.vue"),
          },
        },
        {
          path: "methods/new",
          name: "New Protocol",
          meta: { requiresAuth: true, title: "New Protocol" },
          component: () => import("../pages/NewProtocolPage.vue"),
        },
        {
          path: "experiments/new",
          name: "New Labnote",
          meta: { requiresAuth: true, title: "New Labnote" },
          component: () => import("../pages/NewLabnotePage.vue"),
          alias: "/new",
        },
        {
          path: "workspaces/:workspaceId/duplicate-method/:methodId",
          name: "Duplicate Method",
          meta: { requiresAuth: true, title: "Duplicate Method" },
          component: () => import("../DuplicateMethodPage.vue"),
        },
        {
          path: "workspaces/:workspaceId/duplicate-experiment/:experimentId",
          name: "Duplicate Experiment",
          meta: { requiresAuth: true, title: "Duplicate Experiment" },
          component: () => import("../DuplicateExperimentPage.vue"),
        },
        {
          path: "workspaces/:workspaceId/duplicate-experiment-delta/:experimentId",
          name: "Duplicate Experiment With Changes",
          meta: {
            requiresAuth: true,
            title: "Duplicate Experiment With Changes",
          },
          component: () => import("../DuplicateExperimentWithChangesPage.vue"),
        },
        {
          path: "workspaces/new",
          name: "New Workspace",
          meta: { requiresAuth: true, title: "New Workspace" },
          component: () => import("../NewWorkspacePage.vue"),
        },
        {
          path: "workspaces/:workspaceId",
          name: "Workspace",
          meta: { requiresAuth: true },
          components: {
            default: () => import("../WorkspacePage.vue"),
            topBarMiddle: () => import("../WorkspaceTopBar.vue"),
          },
          beforeEnter: (to, from, next) => {
            to.meta.title = `Workspace - ${to.params.workspaceId}`;
            next();
          },
        },
        {
          path: "trash/:workspaceId",
          name: "Trash",
          meta: { requiresAuth: true, title: "Trash" },
          component: () => import("../TrashPage.vue"),
        },
      ],
    },
  ],
});

// Use afterEach guard to change the document title
router.afterEach((to) => {
  const defaultTitle = "Briefly, Experiment Builder";
  const pageTitle =
    typeof to.meta.title === "string" ? to.meta.title : defaultTitle;
  document.title = pageTitle;
});

router.beforeEach(async (to) => {
  // routes with `meta: { requiresAuth: true }` will check for
  // the users, others won't
  if (to.meta.requiresAuth) {
    const currentUser = await getCurrentUser();
    // if the user is not logged in, redirect to the login page
    if (!currentUser) {
      return {
        path: "/login",
        query: {
          // we keep the current path in the query so we can
          // redirect to it after login with
          // `router.push(route.query.redirect || '/')`
          redirect: to.fullPath,
        },
      };
    }
  }
  // TODO: It makes initial loading slower. Consider removing it
  // when we don't have router changing flags.
  await new Promise<void>((resolve) => {
    setTimeout(() => {
      // If it takes too long resolve with default values.
      resolve();
    }, 500);
    posthog.onFeatureFlags(() => {
      resolve();
    });
  });
});
export default router;
